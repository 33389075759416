@import "assets/master";

/* ------------------------------------
    Misc
--------------------------------------- */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.button-primary, .amplify-button[data-variation='primary'] {
  background-color: $brandHighlightColor;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
  &:hover {
      background-color: $brandLightColor;
    }
}

.amplify-button[aria-label='Show password'] {
  &:hover {
    background-color: $brandLightColor;
  }
  &:focus {
    background-color: #ffe0af;
  }
}

.amplify-button[data-variation='link'] {
  color: $brandHighlightColor;
  &:hover{
    color: white;
    background-color: $brandLightColor;
  }
}

.button-open-query {
  background-color: $openQueryColor;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.button-my-files {
  background-color: $myFilesColor;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.button-secondary {
  background-color: $brandLightColor;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.button-cancel {
  background-color: $brandLightColor;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.header-button {
  background-color: white;
  color: $brandHighlightColor;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.button-red {
  background-color: red;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.white-text {
  color: white;
}

.inline-block {
  display: inline-block;
}

.no-bullets {
  list-style-type: none;
}

.text-align-right {
  text-align: right;
}

.bar-text {
  color: white;
  font-weight: bold;
  font-size: 1.125rem;
}

// In IE11, words weren't wrapping.  Found the following post to fix:
// https://stackoverflow.com/questions/46111357/word-break-break-word-not-working-in-firefox-and-ie
.break-word {
  display: flex;
  flex-direction: column;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
}

.width-100 {
  width: 100%;
}

.account-header-button {
  background-color: white;
  color: $brandHighlightColor;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}