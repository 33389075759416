@import "../../../assets/master";

.account-header {
  background-color: $brandHighlightColor;
  height: $headerRowHeight;
  border-radius: $sectionBorderRadius $sectionBorderRadius 0 0;

  margin-left: $contentLeftMargin;

  // IE11 doesn't support grid-row-gap or grid-column-gap.
  // Add alternative CSS for IE11.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-top: 1rem;
    margin-left: $contentLeftMargin;
  }

  padding: 0.625rem 1.25rem 0 1.25rem;
}

.account-header-name {
  color: white;
  display: inline-block;
}

.content {
  margin-left: $contentLeftMargin;
  border-left: 0.04rem solid lightgrey;
  border-right: 0.04rem solid lightgrey;
  border-bottom: 0.04rem solid lightgrey;
  border-radius: 0 0 $sectionBorderRadius $sectionBorderRadius;
}
