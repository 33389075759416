@import "../../../assets/master";

.create-scope-button {
  background-color: white;
  color: $brandHighlightColor;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.header-bar {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;

  background-color: $brandHighlightColor;
  height: $headerRowHeight;
  border-radius: $sectionBorderRadius $sectionBorderRadius 0 0;

  margin-left: $contentLeftMargin;
  padding: 0.625rem 1.25rem 3rem 0;
}

.column-headers-bar {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;

  background-color: $brandLightColor;
  height: 1.6rem;
  margin-left: $contentLeftMargin;
}

.content {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;

  margin-left: $contentLeftMargin;
  border-left: 0.04rem solid lightgrey;
  border-right: 0.04rem solid lightgrey;
  border-bottom: 0.04rem solid lightgrey;
  border-radius: 0 0 $sectionBorderRadius $sectionBorderRadius;
}

.team-start-end {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
