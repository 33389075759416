@import "../../assets/_master.scss";

.header-background {
  background-color: $brandHighlightColor;
  color: white;
}

.modal-button {
  background-color: $brandHighlightColor;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.modal-button-no {
  
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0 0 0 0.5rem;
}
