@import "../../assets/master";

.user {
  margin-left: $contentLeftMargin;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.user-header {
  flex: 0 1 auto;
  background-color: $brandHighlightColor;
  height: $headerRowHeight;
  border-radius: $sectionBorderRadius $sectionBorderRadius 0 0;

  // IE11 doesn't support grid-row-gap or grid-column-gap.
  // Add alternative CSS for IE11.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-top: 1rem;
    margin-left: $contentLeftMargin;
  }

  padding: 0.625rem 1.25rem 0 1.25rem;
}

.user-content {
  flex: 1 1 auto;
  border-radius: 0 0 $sectionBorderRadius $sectionBorderRadius;
  border-left: 0.04rem solid lightgrey;
  border-right: 0.04rem solid lightgrey;
  border-bottom: 0.04rem solid lightgrey;

  // IE11 doesn't support grid-row-gap or grid-column-gap.
  // Add alternative CSS for IE11.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-bottom: 2rem;
    margin-left: $contentLeftMargin;
  }
}

.user-header-name {
  color: white;
  display: inline-block;
}

.user-header-manage {
  float: right;
  padding: 0;
  margin: -0.188rem 0 0 0;
}

.user-header-button {
  background-color: white;
  color: $brandHighlightColor;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}
