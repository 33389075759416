$brandHighlightColor: #ff9900;
$brandLightColor: #ffb300;
$sectionBorderRadius: 0.5rem;
$headerRowHeight: 3.25rem;
$contentLeftMargin: 2rem;

// $openQueryColor: #eb5757;//prior red
// $openQueryColor: #8DC63E;//TEAM light green
$openQueryColor: #439945;//TEAM dark green
$myFilesColor: #828282;
