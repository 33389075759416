@import "../../assets/master";

.sidebar {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: Sidebar;
  border-radius: $sectionBorderRadius;

  display: flex;
  width: 100%;
  align-items: stretch;
  border-left: 0.04rem solid lightgrey;
  border-right: 0.04rem solid lightgrey;
  border-bottom: 0.04rem solid lightgrey;

  // IE11 doesn't support grid-row-gap or grid-column-gap.
  // Add alternative CSS for IE11.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-top: 1rem;
  }
}

.sidebar-nav {
  width: 100%;
  color: white;
}

.sidebar-item {
  color: black;
  padding: 0.625rem;
}

.sidebar-menu {
  background-color: $brandHighlightColor;
  border-radius: 0.625rem 0.625rem 0 0;
  border-top: 0.0625rem solid $brandHighlightColor;
  border-left: 0.0625rem solid $brandHighlightColor;
  border-right: 0.0625rem solid $brandHighlightColor;
  padding: 0.625rem;
  height: $headerRowHeight;
}

.open-query-sidebar-menu {
  background-color: $openQueryColor;
  border-radius: 0.625rem 0.625rem 0 0;
  border-top: 0.0625rem solid $openQueryColor;
  border-left: 0.0625rem solid $openQueryColor;
  border-right: 0.0625rem solid $openQueryColor;
  padding: 0.625rem;
  height: $headerRowHeight;
}

.my-files-sidebar-menu {
  background-color: $myFilesColor;
  border-radius: 0.625rem 0.625rem 0 0;
  border-top: 0.0625rem solid $myFilesColor;
  border-left: 0.0625rem solid $myFilesColor;
  border-right: 0.0625rem solid $myFilesColor;
  padding: 0.625rem;
  height: $headerRowHeight;
}

.sidebar-applications {
  background-color: $brandHighlightColor;
  border-left: 0.0625rem solid $brandHighlightColor;
  border-right: 0.0625rem solid $brandHighlightColor;
  padding: 0.625rem;
  height: $headerRowHeight;
}

.open-query-sidebar-applications {
  background-color: $openQueryColor;
  border-left: 0.0625rem solid $openQueryColor;
  border-right: 0.0625rem solid $openQueryColor;
  padding: 0.625rem;
  height: $headerRowHeight;
}

.my-files-sidebar-applications {
  background-color: $myFilesColor;
  border-left: 0.0625rem solid $myFilesColor;
  border-right: 0.0625rem solid $myFilesColor;
  padding: 0.625rem;
  height: $headerRowHeight;
}

.content {
  display: flex;
}

.content img {
  margin-right: 0.625rem;
  display: block;
}

.content p {
  margin: 0;
}
