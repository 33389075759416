@import "../../assets/_master.scss";

.modal-50w {
  max-width: 50% !important;
}

.modal-35w {
  max-width: 35% !important;
}

.modal-25w {
  max-width: 25% !important;
}

.header-background {
  background-color: $brandHighlightColor;
}

.modal-button {
  background-color: $brandHighlightColor;
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0;
}

.modal-button-cancel {
  
  color: white;
  border-radius: $sectionBorderRadius;
  border: none;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  margin: 0 0 0 0.5rem;
}
