@import "../../assets/master";

.container {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  grid-area: Content2;
  border-radius: $sectionBorderRadius;
  margin-left: $contentLeftMargin;

  // IE11 doesn't support grid-row-gap or grid-column-gap.
  // Add alternative CSS for IE11.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-left: 4rem;
  }

  display: flex;
  flex-flow: column;
  height: 100%;
}

.accounts-bar {
  flex: 0 1 auto;
  background-color: $brandHighlightColor;
  height: $headerRowHeight;
  border-radius: $sectionBorderRadius $sectionBorderRadius 0 0;
}

.column-headers-bar {
  flex: 0 1 auto;
  background-color: $brandLightColor;
  height: 1.6rem;
}

.content {
  border-left: 0.04rem solid lightgrey;
  border-right: 0.04rem solid lightgrey;
  border-bottom: 0.04rem solid lightgrey;
  border-radius: 0 0 $sectionBorderRadius $sectionBorderRadius;
}
