@import "../../assets/master";

.content {
  margin-left: $contentLeftMargin;
  margin-top: 2rem;

  // IE11 doesn't support grid-row-gap or grid-column-gap.
  // Add alternative CSS for IE11.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-left: $contentLeftMargin;
  }
}
