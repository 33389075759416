@import "../../assets/_master.scss";

.user-name {
  padding-right: 0.625rem;
}

.header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Header;
}

.notification {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: 0;
  //padding: 0.938rem 1.625rem;
  padding: 0.938rem 0.8rem;
}

.notification .badge {
  display: inline-block;
  padding: 2px;
  line-height: 100%;

  -moz-border-radius: 50%;
  border-radius: 50%;

  background-color: red;
  color: white;
  text-align: center;
  font-size: 0.6em;
  top: 1rem;
  right: 1rem;
  position: absolute;
}

button:focus {
  outline: none;
}

.white-background {
  background: white;
}

/*button span {
  color: white;
}*/

.notifications-table tr:hover {
  background-color: white;
}
